import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiAlliedStar } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Whispering Pines Mini Evleri
			</title>
			<meta name={"description"} content={"Huzurun konforla buluştuğu cennet gibi bir inziva yeri. Doğal bir manzara içinde yer alan mini evlerimiz, gözlerden uzak yaşamanın cazibesini modern olanakların konforuyla harmanlayarak eşsiz bir kaçamak deneyimi sunuyor.\n"} />
			<meta property={"og:title"} content={"Home | Whispering Pines Mini Evleri"} />
			<meta property={"og:description"} content={"Huzurun konforla buluştuğu cennet gibi bir inziva yeri. Doğal bir manzara içinde yer alan mini evlerimiz, gözlerden uzak yaşamanın cazibesini modern olanakların konforuyla harmanlayarak eşsiz bir kaçamak deneyimi sunuyor."} />
			<meta property={"og:image"} content={"https://kimbachi.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://kimbachi.live/img/rent-vector-icon.jpg"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://kimbachi.live/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Whispering Pines Mini Evlerine Hoş Geldiniz
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Huzurun konforla buluştuğu cennet gibi bir inziva yeri. Doğal bir manzara içinde yer alan mini evlerimiz, gözlerden uzak yaşamanın cazibesini modern olanakların konforuyla harmanlayarak eşsiz bir kaçamak deneyimi sunuyor.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Bize Ulaşın
				</Link>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://kimbachi.live/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://kimbachi.live/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Açık Hava Etkinlikleri - Kapınızın önündeki yürüyüş parkurları, kuş gözlemciliği ve yıldız gözlemciliği ile açık havayı keşfedin.
					<br />
					<br />
					Samimi İnzivalar - Samimi ve gençleştirici bir deneyim arayan çiftler, yalnız seyahat edenler veya küçük aileler için idealdir.
				</Text>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Neden Whispering Pines'ı Seçmelisiniz?
				</Text>
				<Text
					color="--darkL2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					Her mini ev, doğal güzelliklerle çevrili, koşuşturmadan huzurlu bir kaçış sunan mahremiyet için tasarlanmıştır.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--primary"
						size="64px"
						category="gi"
						icon={GiAlliedStar}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 18px 0px" color="--darkL2" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Rustik Bir Ortamda Modern Konfor
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Her iki dünyanın da en iyisinin tadını çıkarın, mini evlerimiz modern olanaklarla donatılmıştır ve rustik cazibesini kaybetmeden konforlu bir konaklama sağlar.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--primary"
						size="64px"
						category="gi"
						icon={GiAlliedStar}
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Eşsiz Mimari Tasarım
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						Mini evlerimiz, her biri kendi karakterine ve tarzına sahip, unutulmaz ve fotojenik bir konaklama sağlayan benzersiz tasarımlara sahiptir.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--primary"
						size="64px"
						category="gi"
						icon={GiAlliedStar}
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
							Çevre Dostu Yaşam
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Sürdürülebilirlik konusunda kararlı olan mini evlerimiz çevre dostudur ve doğal çevre üzerindeki etkilerini en aza indirir.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});